import React, { useState } from 'react'

const GlobalContext = React.createContext()

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false)
  const [showSidebarDashboard, setShowSidebarDashboard] = useState(true)
  const [applicationModalVisible, setApplicationModalVisible] = useState(false)
  const [signInModalVisible, setSignInModalVisible] = useState(false)
  const [signUpModalVisible, setSignUpModalVisible] = useState(false)
  const [videoModalVisible, setVideoModalVisible] = useState(false)
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false)
  const [header, setHeader] = useState({
    theme: 'light',
    bgClass: 'default',
    variant: 'primary',
    align: 'left',
    isFluid: false,
    button: 'cta', // profile, cart, cta, account, null
    buttonText: 'Get In Touch', // profile, account, null
    reveal: true,
  })
  const [footer, setFooter] = useState({
    theme: 'dark',
    style: 'style2', //style1, style2
  })

  const toggleTheme = () => {
    setThemeDark(!themeDark)
  }

  const toggleSidebarDashboard = () => {
    setShowSidebarDashboard(!showSidebarDashboard)
  }

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible)
  }

  const toggleApplicationModal = () => {
    setApplicationModalVisible(!applicationModalVisible)
  }

  const toggleSignInModal = () => {
    setSignInModalVisible(!signInModalVisible)
  }

  const toggleSignUpModal = () => {
    setSignUpModalVisible(!signUpModalVisible)
  }

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas)
  }

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false)
  }

  return (
    <GlobalContext.Provider
      value={{
        themeDark,
        toggleTheme,
        showSidebarDashboard,
        toggleSidebarDashboard,
        videoModalVisible,
        toggleVideoModal,
        applicationModalVisible,
        toggleApplicationModal,
        signInModalVisible,
        toggleSignInModal,
        signUpModalVisible,
        toggleSignUpModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
        header,
        setHeader,
        footer,
        setFooter,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { GlobalProvider }
