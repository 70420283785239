import React from 'react'
import { Link } from 'gatsby'

import imgL1Logo from '../../assets/image/logo-main-black.png'
import imgL1LogoWhite from '../../assets/image/logo-main-white.png'

const Logo = ({ white, height, className = '', ...rest }) => {
  return (
    <Link to="/" aria-label="logo" className={`d-block ${className}`} {...rest}>
      {white ? (
        <img
          src={imgL1LogoWhite}
          alt="Logo"
          style={{ maxWidth: 220, width: 180 }}
        />
      ) : (
        <img src={imgL1Logo} alt="" style={{ maxWidth: 220, width: 180 }} />
      )}
    </Link>
  )
}

export default Logo
