import React from 'react'
// import  { useContext } from "react";
import { Link } from 'gatsby'
// import GlobalContext from "../../context/GlobalContext";
import Logo from '../Logo'

const footerItems = [
  {
    'social-icons': [
      {
        name: 'facebook',
        link: 'https://www.facebook.com/profile.php?id=100092460195349',
        className: 'fab fa-facebook-f',
      },
      {
        name: 'twitter',
        link: 'https://twitter.com/PuretimeA',
        className: 'fab fa-twitter',
      },
      {
        name: 'linkedin',
        link: 'https://www.linkedin.com/in/puretimeworldrecruitment/',
        className: 'fab fa-linkedin-in',
      },
      // {
      //   name: "whatsapp",
      //   link: "https://wa.me/",
      //   className: "fab fa-whatsapp",
      // },
      {
        name: 'instagram',
        link: 'https://www.instagram.com/ptworldrecruitment/',
        className: 'fab fa-instagram',
      },
    ],
  },
  {
    'footer-links': {
      Company: [
        {
          name: 'About Us',
          link: 'about-us',
        },
        {
          name: 'Services',
          link: 'services',
        },
        {
          name: 'Contact Us',
          link: 'contact',
        },
        // {
        //   name: "FAQ",
        //   link: "faq",
        // },
      ],
      Legal: [
        {
          name: 'Privacy Policy',
          link: 'https://www.privacypolicies.com/live/b8fb8a16-7e65-4c5e-ba33-1ccd1fe1f91d',
        },
        {
          name: 'Terms & Conditions',
          link: 'https://www.privacypolicies.com/live/aa27b41a-6a6d-47c8-985d-b5ef002167cf',
        },
        {
          name: 'Cookie Policy',
          link: 'https://www.privacypolicies.com/live/91efb48c-dbf2-4d87-b5de-b9cade1facad',
        },
      ],
    },
  },
]

const Footer = () => {
  // const gContext = useContext(GlobalContext);
  return (
    <>
      <footer className="footer bg-ebony-clay dark-mode-texts">
        <div className="container">
          {/* <!-- Cta section --> */}
          <div className="pt-11 pt-lg-20 pb-13 pb-lg-20 border-bottom border-width-1 border-default-color-2">
            <div className="row justify-content-center ">
              <div
                className="col-xl-7 col-lg-12"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                {/* <!-- cta-content start --> */}
                <div className="pb-xl-0 pb-9 text-xl-left text-center">
                  <h2 className="text-white font-size-8 mb-4">
                    Take the First Step Towards Success
                  </h2>
                  <p className="text-hit-gray font-size-5 mb-0">
                    Unlock Your Potential and Find Opportunities Tailored to You
                  </p>
                </div>
                {/* <!-- cta-content end --> */}
              </div>
              <div
                className="col-xl-5 col-lg-12"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                {/* <!-- cta-btns start --> */}
                <div className="btns d-flex justify-content-xl-end justify-content-center align-items-xl-center flex-wrap h-100  mx-n4">
                  <a
                    className="btn btn-puretime btn-h-60 btn-xl mx-2 mt-6 text-uppercase"
                    href="/contact"
                    rel="noopener noreferrer"
                  >
                    Get in Contact
                  </a>
                </div>
                {/* <!-- cta-btns end --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-12 pt-lg-19 pb-10 pb-lg-19">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mb-lg-0 mb-9">
              {/* <!-- footer logo start --> */}
              <Logo white className="footer-logo mb-10" />
              {/* <!-- footer logo End --> */}
              {/* <!-- media start --> */}
              {/*<div className="media mb-11">
                <i className="fas fa-map-marker-alt fa-lg py-7 text-primary"></i>
                <div className="media-body pl-5">
                  <p className="mb-0 font-size-4 text-white">7 Bell Yard,</p>
                  <p className="mb-0 font-size-4 font-weight-bold text-primary">
                    London, WC2A 2JR
                  </p>
                </div>
              </div>*/}
              <div className="media mb-11">
                <i className="far fa-envelope fa-lg py-7 text-primary"></i>
                <div className="media-body pl-5">
                  <p className="mb-0 font-size-4 text-white">Contact us at</p>
                  <Link
                    to="/contact"
                    className="mb-0 font-size-4 font-weight-bold"
                    href="mailto:info@puretimeworld.com"
                  >
                    info@puretimeworld.com
                  </Link>
                </div>
              </div>
              <div className="media mb-11">
                <i className="fas fa-phone-alt fa-lg py-7 text-primary"></i>
                <div className="media-body pl-5">
                  <p className="mb-0 font-size-4 text-white">Call us on</p>
                  <a
                    className="mb-0 font-size-4 font-weight-bold"
                    href="tel:+442045927960"
                  >
                    0204 592 7960
                  </a>
                </div>
              </div>
              {/* <!-- media start --> */}
              {/* <!-- widget social icon start --> */}
              <div className="social-icons">
                <ul className="pl-0 list-unstyled d-flex align-items-end ">
                  <li className="d-flex flex-column justify-content-center px-3 mr-3 font-size-4 heading-default-color">
                    Follow us on:
                  </li>
                  {footerItems[0]['social-icons'].map((footerItem, index) => (
                    <li className="d-flex flex-column justify-content-center px-3 mr-3">
                      <a
                        key={index}
                        href={footerItem.link}
                        aria-label={footerItem.name}
                        target="_blank"
                        className="hover-color-primary heading-default-color"
                        rel="noopener noreferrer"
                      >
                        <i
                          className={`${footerItem.className}  font-size-3 pt-2`}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* <!-- widget social icon end --> */}
            </div>
            <div className="col-lg-8 col-md-6 col-xs-12">
              <div className="row">
                <div className="col-md-6 col-xs-6">
                  <div className="footer-widget widget2 mb-md-0 mb-13">
                    {/* <!-- footer widget title start --> */}
                    <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                      Company
                    </p>
                    {/* <!-- footer widget title end --> */}
                    {/* <!-- widget social menu start --> */}
                    <ul className="widget-links pl-0 list-unstyled list-hover-primary">
                      {footerItems[1]['footer-links']['Company'].map(
                        (footerItem, index) => (
                          <li className="mb-6">
                            <Link
                              key={index}
                              className="heading-default-color font-size-4 font-weight-normal"
                              to={`/${footerItem.link}`}
                            >
                              {footerItem.name}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                    {/* <!-- widget social menu end --> */}
                  </div>
                </div>
                <div className="col-md-6 col-xs-6">
                  <div className="footer-widget widget4">
                    {/* <!-- footer widget title start --> */}
                    <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                      Legal
                    </p>
                    {/* <!-- footer widget title end --> */}
                    <ul className="widget-links pl-0 list-unstyled list-hover-primary">
                      {footerItems[1]['footer-links']['Legal'].map(
                        (footerItem, index) => (
                          <li className="mb-6">
                            <a
                              key={index}
                              className="heading-default-color font-size-4 font-weight-normal"
                              href={`${footerItem.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {footerItem.name}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
