export const menuItems = [
  {
    name: '',
    label: 'Home',
  },
  {
    name: 'about-us',
    label: 'About Us',
  },
  {
    name: 'services',
    label: 'Services',
    //  items: [
    //    {
    //      name: "job-seeker",
    //      label: "Job Seeker",
    //    },
    //    {
    //      name: "employer",
    //      label: "Employer",
    //    },
    //  ],
  },
  //  {
  //    name: "vacancies",
  //    label: "Vacancies",
  //  },
  // {
  //   name: "https://uxtheme.net/product-support/",
  //   label: "Support",
  //   isExternal: true,
  // },
  {
    name: 'contact',
    label: 'Contact Us',
  },
]
